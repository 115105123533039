<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/webinars`"
        />

        <div class="pageContent backgroundZircon">
            <VideoArticle
                v-if="live"
                :customIframe="(live && live.player_code) || ''"
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import VideoArticle from "@/views/components/VideoArticle/VideoArticle";

import { getDateFromDatetime } from "../../../../helpers/dateFormat";

export default {
    name: "webinarsRecording",
    components: {
        Layout,
        PageHeader,
        ButtonVideoProblem,
        VideoArticle,
    },

    watch: {
        "$route.fullPath": function() {
            this.getLiveData();
        },
    },

    beforeMount() {
        this.getLiveData();
    },

    data() {
        return {
            title: "Онлайн-трансляция",
            items: [
                {
                    text: "Вебинары",
                    to: `/${this.$route.params.slug}/webinars`,
                },
                {
                    text: "Онлайн-трансляция",
                    active: true,
                },
            ],

            live: {},
        };
    },

    methods: {
        async getLiveData() {
            const response = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/live"
            );

            if (response && response.data && response.data.result) {
                this.live = response.data.live;
            } else {
                await this.$router.push("/" + this.$route.params.slug + "/webinars");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            (response && response.data && response.data.error) ||
                            "Ошибка при загрузке трансляции",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import "./webinarsLive.scss";
</style>
